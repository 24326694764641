@import "./mixins";
@import "./define";

//Reset Style
* {
  box-sizing: border-box;
  margin: 0;
  font-feature-settings: "palt" 1;
  padding: 0;
}

html,
body {
  overscroll-behavior: none;
  font-weight: normal;
}
textarea {
  resize: none;
  &:focus {
    outline: none;
  }
}
a {
  color: inherit;
  text-decoration: none;
  line-height: 1.6;
}
ul {
  list-style: none;
}
.bold {

}
input {
  box-shadow: none;
  &:focus {
    outline: none;
  }
}
.en {
  font-weight: normal;
}

/*For Responsive*/
.pc_only {
  display: block;
  @include max-screen(767px) {
    display: none;
  }
}
.sp_only {
  display: none;
  @include max-screen(767px) {
    display: block;
  }
}
/* main */
main {
  overflow: hidden;
  min-height: 100vh;
  position: relative;
  display: block;
}
img {
  max-width: 100%;
}
body {
  line-height: 1.6;
  font-size: 16px;
  font-weight: 300;
  font-family: "Noto Sans JP", sans-serif;
}
input,
textarea {
  font-family: "corpmarugo_v1", sans-serif;
  font-weight: 700;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.route-wrapper {
  position: relative;
}

.route-wrapper > div {
  position: absolute;
}

.page {
  background-color: #fff;
  width: 100%;
  min-height: 100vh;
}

.pageTransition {
  &-exit {
    position: absolute;
    background-color: #fff;
    width: 100%;
    opacity: 1;
    z-index: 1;
    &-active {
      z-index: 1;
      transition: opacity 0.1s ease-out;
      opacity: 0;
    }
    &-done {
    }
  }
  
  &-enter {
    background-color: #fff;
    width: 100%;
    opacity: 0;
    z-index: 2;
    &-active {
      transition: opacity 0.5s ease-out 0.2s;
      z-index: 2;
      opacity: 1;
    }
    &-done {
    }
  }
}

  #calendarTitle{
    color: red!important;
  }

.rich-text-content {
  font-size: 16px;
  line-height: 2.2;
  word-break: break-all;
  letter-spacing: 4px;
  @include max-screen(767px) {
    font-size: 16px;
  }
  h2 {
    font-size: 22px;
    letter-spacing: 5px;
    @include max-screen(767px) {
      font-size: 20px;
    }
  }
  h3 {
    font-size: 20px;
    letter-spacing: 5px;
    @include max-screen(767px) {
      font-size: 18px;
    }
  }
  p,
  h1,
  h2,
  h3 {
    margin: 15px 0;
    font-weight: 300;
  }
  a {
    color: blue;
    text-decoration: underline;
  }
  img {
    height: auto;
  }
}